//
// EDR Additions to Boostrap Less
// --------------------------------------------------


// Button color styles
// Modifies elements that can be attached to buttons
// to use the button styling so they match
// --------------------------------------------------

.btn-primary {
  & + ul.dropdown-menu {
    border-top: 4px solid darken(@brand-primary, 10%);

    li > a {
      &:hover,
      &:focus {
        background-color: darken(@brand-primary, 10%);
      }
    }

    .active > a {
      &,
      &:hover,
      &:focus {
        background-color: darken(@brand-primary, 10%);
      }
    }
  }
  & + .popover {
    border-top: 4px solid @brand-primary;

    & > .popover-title {
      color: @brand-primary;
    }
  }
}

.btn-success {
  & + ul.dropdown-menu {
    border-top: 4px solid darken(@brand-success, 10%);

    li > a {
      &:hover,
      &:focus {
        background-color: darken(@brand-success, 10%);
      }
    }

    .active > a {
      &,
      &:hover,
      &:focus {
        background-color: darken(@brand-success, 10%);
      }
    }
  }
  & + .popover {
    border-top: 4px solid @brand-success;

    & > .popover-title {
      color: @brand-success;
    }
  }
}

.btn-info {
  & + ul.dropdown-menu {
    border-top: 4px solid darken(@brand-info, 10%);

    li > a {
      &:hover,
      &:focus {
        background-color: darken(@brand-info, 10%);
      }
    }

    .active > a {
      &,
      &:hover,
      &:focus {
        background-color: darken(@brand-info, 10%);
      }
    }
  }
  & + .popover {
    border-top: 4px solid @brand-info;

    & > .popover-title {
      color: @brand-info;
    }
  }
}

.btn-warning {
  & + ul.dropdown-menu {
    border-top: 4px solid darken(@brand-warning, 10%);

    li > a {
      &:hover,
      &:focus {
        background-color: darken(@brand-warning, 10%);
      }
    }

    .active > a {
      &,
      &:hover,
      &:focus {
        background-color: darken(@brand-warning, 10%);
      }
    }
  }
  & + .popover {
    border-top: 4px solid @brand-warning;

    & > .popover-title {
      color: @brand-warning;
    }
  }
}

.btn-danger {
  & + ul.dropdown-menu {
    border-top: 4px solid darken(@brand-danger, 10%);

    li > a {
      &:hover,
      &:focus {
        background-color: darken(@brand-danger, 10%);
      }
    }

    .active > a {
      &,
      &:hover,
      &:focus {
        background-color: darken(@brand-danger, 10%);
      }
    }
  }
  & + .popover {
    border-top: 4px solid @brand-danger;

    & > .popover-title {
      color: @brand-danger;
    }
  }
}

.btn-blue {
  & + ul.dropdown-menu {
    border-top: 4px solid darken(@brand-blue, 10%);

    li > a {
      &:hover,
      &:focus {
        background-color: darken(@brand-blue, 10%);
      }
    }

    .active > a {
      &,
      &:hover,
      &:focus {
        background-color: darken(@brand-blue, 10%);
      }
    }
    & + .popover {
      border-top: 4px solid @brand-blue;

      & > .popover-title {
        color: @brand-blue;
      }
    }
  }
}

.btn-green {
  & + ul.dropdown-menu {
    border-top: 4px solid darken(@brand-green, 10%);

    li > a {
      &:hover,
      &:focus {
        background-color: darken(@brand-green, 10%);
      }
    }

    .active > a {
      &,
      &:hover,
      &:focus {
        background-color: darken(@brand-green, 10%);
      }
    }
  }
  & + .popover {
    border-top: 4px solid @brand-green;

    & > .popover-title {
      color: @brand-green;
    }
  }
}
