//
// EDR Modifications to Bootstrap close.less
// --------------------------------------------------


//
// Close icons
// --------------------------------------------------

.close,
.close .close-default {

  font-size: (@font-size-base * 1.45);
  color: @brand-primary;
  background-color: transparent;
  padding: 0 4px;
  border-radius: 50px;
  .opacity(1);
  text-shadow: none;

  &:hover,
  &:focus {
    color: @white;
    background-color: @close-hover-color;
    .opacity(1);
  }


  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  button& {
    padding: 0 4px;
  }
}

.close-light {
  color: @white;
}

.close-dark {
  color: @gray-dark;
}
