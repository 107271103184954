//
// EDR Modifications to Bootstrap tables.less
// --------------------------------------------------

// Baseline styles

.table {
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid @brand-primary;
    color: @brand-primary;
    text-transform: uppercase;
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid @brand-primary;
  }

  > tfoot > tr > td {
    color: @brand-primary;
    font-weight: bold;
    border-top: 2px solid @brand-primary;
    text-transform: uppercase;
  }
}

.table-header-gradated {
  > thead > tr > th {
    #gradient > .vertical(@start-color: #FFF; @end-color: #E7E7E7; @start-percent: 20%; @end-percent: 100%);
    border: 1px solid #E6E6E6;
    border-bottom: 2px solid @brand-primary;
  }

  > tfoot > tr > td {
    #gradient > .vertical(@start-color: #FFF; @end-color: #E7E7E7; @start-percent: 20%; @end-percent: 100%);
    border: 1px solid #E6E6E6;
    border-top: 2px solid @brand-primary;
  }
}

.table-header-shaded {
  > thead > tr > th,
  > tfoot > tr > td {
    background-color: #F6F6F6;
  }
}
