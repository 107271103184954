//
// EDR Modifications to Bootstrap panels.less
// --------------------------------------------------


//
// Panels
// --------------------------------------------------


// Base class
.panel {
  border-radius: @border-radius-small;
  //.box-shadow(0px 1px 1px 0px rgba(0,0,0,0.2));
}

// Optional heading
.panel-heading {
  //.border-top-radius(@border-radius-small);
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
  .border-bottom-radius(@border-radius-small);
}


//
// Accordion Panel (.panel-group)
// --------------------------------------------------

.panel-group {
  .panel-heading {
    a {
      display: block;
      text-decoration: none;

      &:after {
        font-family: 'Glyphicons Halflings';
        content: "\2212";
        float: right;
      }

      &.collapsed {
        &:after {
          content: "\2b";
        }
      }
    }
  }

  .panel-body {
    p,
    ul,
    ol {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
