//
// EDR Modifications to Bootstrap navs.less
// --------------------------------------------------


// Base class
// --------------------------------------------------

.nav {

  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      background-color: transparent; //@nav-link-hover-bg;
      border-color: transparent; //@link-color;
    }
  }
}


// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  border-bottom: 1px solid @nav-tabs-border-color;
  margin-bottom: 10px;
  > li {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: -1px;

    // Actual tabs (as links)
    > a {
      margin-right: 2px;
      line-height: @line-height-base;
      border: 1px solid transparent;
      border-radius: @border-radius-base @border-radius-base 0 0;
      text-transform: uppercase;
      &:hover {
        background-color: @nav-tabs-link-hover-bg;
        text-decoration: underline;
        color: @link-hover-color;
        border-color: @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color @nav-tabs-border-color;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: @nav-tabs-active-link-hover-color;
        font-weight: bold;
        background-color: @nav-tabs-active-link-hover-bg;
        border: 1px solid @nav-tabs-active-link-hover-border-color;
        border-bottom-color: transparent;
        cursor: default;
        text-decoration: none;
      }
    }
  }
  // pulling this in mainly for less shorthand
  &.nav-justified {
    .nav-justified();
    .nav-tabs-justified();
  }
}


// Pills
// -------------------------
.nav-pills {
  > li {
    float: left;

    // Links rendered as pills
    > a {
      border-radius: @nav-pills-border-radius;
      text-transform: uppercase;
    }
    + li {
      margin-left: 2px;
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        font-weight: bold;
        color: @nav-pills-active-link-hover-color;
        background-color: @nav-pills-active-link-hover-bg;
      }
    }
  }

  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      background-color: @nav-link-hover-bg;
      border-color: @link-color;
      text-transform: uppercase;
    }
  }
}


// Stacked pills
.nav-stacked {
  > li {
    float: none;
    + li {
      margin-top: 2px;
      margin-left: 0; // no need for this gap between nav items
    }
  }
}

// Large Variant of Stacked pills
.nav-stacked-lg {
  > li {
    float: none;

    > a {
      font-family: @headings-font-family;
      font-size: ceil((@font-size-base * 1.23)); // ~16px
      font-weight: bold;
    }

    + li {
      margin-top: 2px;
      margin-left: 0; // no need for this gap between nav items
    }
  }
}

// Large Variant of Stacked pills
// with no border-radius. Useful for sidebars!
.nav-stacked-lg-no-radius {
  > li {
    float: none;

    > a {
      border-radius: 0px;
      border-radius: none;
      font-family: @headings-font-family;
      font-size: ceil((@font-size-base * 1.23)); // ~16px
      font-weight: bold;
    }

    + li {
      margin-top: 2px;
      margin-left: 0; // no need for this gap between nav items
    }
  }
}


// Nav variations
// --------------------------------------------------

// Justified nav links
// -------------------------

.nav-justified {
  width: 100%;

  > li {
    float: none;
    > a {
      text-align: center;
      margin-bottom: 5px;
    }
  }

  > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
  }

  @media (min-width: @screen-sm-min) {
    > li {
      display: table-cell;
      width: 1%;
      > a {
        margin-bottom: 0;
      }
    }
  }
}

// Move borders to anchors instead of bottom of list
//
// Mixin for adding on top the shared `.nav-justified` styles for our tabs
.nav-tabs-justified {
  border-bottom: 0;

  > li > a {
    // Override margin from .nav-tabs
    margin-right: 0;
    border-radius: @border-radius-base;
  }

  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    border: 1px solid @nav-tabs-justified-link-border-color;
  }

  @media (min-width: @screen-sm-min) {
    > li > a {
      border-bottom: 1px solid @nav-tabs-justified-link-border-color;
      border-radius: @border-radius-base @border-radius-base 0 0;
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-bottom-color: @nav-tabs-justified-active-link-border-color;
    }
  }
}


// Tabbable tabs
// -------------------------

// Hide tabbable panes to start, show them when `.active`
.tab-content {
  > .tab-pane {
    display: none;
    visibility: hidden;
  }
  > .active {
    display: block;
    visibility: visible;
  }
}


// Dropdowns
// -------------------------

// Specific dropdowns
.nav-tabs .dropdown-menu {
  // make dropdown border overlap tab border
  margin-top: -1px;
  // Remove the top rounded corners here since there is a hard edge above the menu
  .border-top-radius(0);
}
