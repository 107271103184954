//
// EDR Modifications to Bootstrap type.less
// --------------------------------------------------

// Body
// -------------------------
body {
  font-family: @font-family-sans-serif;
}

// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: @headings-font-family;
  text-transform: uppercase;
  font-weight: 300;
  font-weight: bold;
}

h1, .h1 {
  color: @headings-color-h1;
  font-weight: bold;
}

h2, .h2 {
  color: @headings-color-h2;
  border-bottom: 1px solid #eee;
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
  color: @headings-color;
}


// Body text
// -------------------------

p {
  line-height: 2;
}


// Page header
// -------------------------

.page-header, .jumbotron {
  h1 {
    color: @headings-color-h1;
  }
}


// Emphasis & misc
// -------------------------

// Contextual colors
.text-blue {
  .text-emphasis-variant(@brand-blue);
}
.text-green {
  .text-emphasis-variant(@brand-green);
}

// Contextual backgrounds
.bg-blue {
  .bg-variant(@brand-blue);
}
.bg-green {
  .bg-variant(@brand-green);
}
