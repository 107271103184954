//
// Popovers
// --------------------------------------------------


.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: @zindex-popover;
  display: none;
  max-width: @popover-max-width;
  padding: 0px;
  // Reset font and text properties given new insertion method
  font-family: @font-family-base;
  font-size: @font-size-base;
  font-weight: normal;
  line-height: @line-height-base;
  text-align: left;
  background-color: @popover-bg;
  background-clip: padding-box;
  border-color: @popover-border-color;
  border-style: solid;
  border-width: 4px 0 1px 0;
  border-top: 4px solid @popover-border-top;
  border-radius: 0px;
  .border-bottom-radius(@border-radius-small);
  .box-shadow(0px 1px 1px 0px rgba(0,0,0,0.2));

  // Overrides for proper insertion
  white-space: normal;

  // Offset the popover to account for the popover arrow
  &.top     { margin-top: -@popover-arrow-width; }
  &.right   { margin-left: @popover-arrow-width; }
  &.bottom  { margin-top: @popover-arrow-width; }
  &.left    { margin-left: -@popover-arrow-width; }
}

.popover {
  &.bottom > .arrow {
    left: 50%;
    margin-left: -@popover-arrow-outer-width;
    border-top-width: 0;
    border-bottom-color: @brand-primary;
    top: -@popover-arrow-outer-width;
    &:after {
      content: " ";
      top: 1px;
      margin-left: -@popover-arrow-width;
      border-top-width: 0;
      border-bottom-color: @brand-primary;
    }
  }
}
