/*
 * Author  : Hunter Perrin
 * Version : 2.0.1
 * Link    : http://sciactive.com/pnotify/
 */

.ui-pnotify {
  top: 25px;
  right: 25px;
  position: absolute;
  height: auto;
  /* Ensures notices are above everything */
  z-index: 9999;

  .ui-pnotify-shadow {
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.2);
  }
}

/* Hides position: fixed from IE6 */
html > body > .ui-pnotify {
  position: fixed;
}

.ui-pnotify-container {
  background-position: 0 0;
  padding: .8em;
  height: 100%;
  margin: 0;
}

.ui-pnotify-sharp {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.ui-pnotify-title {
  display: block;
  margin-bottom: .4em;
  margin-top: 0;
}

.ui-pnotify-text {
  display: block;
}

.ui-pnotify-icon, .ui-pnotify-icon span {
  display: block;
  float: left;
  margin-right: .2em;
}

/* Alternate stack initial positioning. */
.ui-pnotify.stack-topleft, .ui-pnotify.stack-bottomleft {
  left: 25px;
  right: auto;
}

.ui-pnotify.stack-bottomright, .ui-pnotify.stack-bottomleft {
  bottom: 25px;
  top: auto;
}

.ui-pnotify-closer, .ui-pnotify-sticker {
  float: right;
  margin-left: .2em;
}

/* -- Pulldown */
.ui-pnotify-history-container {
  position: absolute;
  top: 0;
  right: 18px;
  width: 70px;
  border-top: none;
  padding: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-top-right-radius: 0;
  border-top-right-radius: 0;
  /* Ensures history container is above notices. */
  z-index: 10000;

  .ui-pnotify-history-header {
    padding: 2px;
    text-align: center;
  }

  button {
    cursor: pointer;
    display: block;
    width: 100%;
  }

  .ui-pnotify-history-pulldown {
    display: block;
    margin: 0 auto;
  }

  &.ui-pnotify-history-fixed {
    position: fixed;
  }
}


/*
 * EDR MODIFICATION TO CHANGE THE DISPLAY OF ALERTS
 */
.ui-pnotify-container {
  background-color: #FFF;
  padding: 0px;
  overflow: hidden;
  position: relative;
  width: 100%;

  > .ui-pnotify-icon-holder {
    width: 50px;
    height: 100%;
    padding: 10px 8px 10px 11px;
    position: absolute;
    left: 0;
  }

  > .ui-pnotify-text-holder {
    padding: 10px 8px 10px 60px;

    > .ui-pnotify-title,
      .ui-pnotify-text {
      color: #333;
    }
  }

  > .ui-pnotify-closer {
    padding: 8px 8px 8px 0;
  }

  > .ui-pnotify-sticker {
    padding: 8px;
  }

  > .ui-pnotify-icon-holder {

    > .ui-pnotify-icon {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 0px;
      font-size: 26px;
      color: #FFF;
    }
  }

  &.alert-warning {
    border-color: #f9a526;

    > .ui-pnotify-icon-holder {
      background-color: #faaf3f;
      padding-left: 13px;
    }
  }

  &.alert-info {
    border-color: #588da4;

    > .ui-pnotify-icon-holder {
      background-color: #6899ae;
      padding-left: 20px;
    }
  }

  &.alert-success {
    border-color: #b2c275;

    > .ui-pnotify-icon-holder {
      background-color: #bcca87;
    }
  }

  &.alert-danger {
    border-color: #e75f54;

    > .ui-pnotify-icon-holder {
      background-color: #ea746a;
      padding-left: 12px;
    }
  }
}
