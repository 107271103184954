//
// EDR Modifications to Bootstrap input-groups.less
// --------------------------------------------------

// Sizing options
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

.input-group-xs > .form-control,
.input-group-xs > .input-group-addon,
.input-group-xs > .input-group-btn > .btn {
  .input-xs();
}

// Text input groups
// -------------------------
.input-group-addon {

  // Sizing
  &.input-xs {
    padding: @padding-xs-vertical @padding-xs-horizontal;
    font-size: @font-size-xs;
    border-radius: @border-radius-xs;
  }
}


.input-group-bordered {

  > .form-control:first-child {
    .border-right-radius(0);
  }

  > .form-control:last-child {
    .border-left-radius(0);
  }

  .input-group-addon {
    color: @white;
    background-color: @input-group-bordered-addon-bg;
    border: 1px solid @input-group-bordered-addon-border-color;
    border-radius: (@border-radius-base * 2);

    // Sizing
    &.input-sm {
      border-radius: (@border-radius-small * 2);
    }
    &.input-lg {
      border-radius: (@border-radius-large * 2);
    }

    &:first-child {
      .border-right-radius(0);
      border-right: 0;
    }

    &:last-child {
      .border-left-radius(0);
      border-left: 0;
    }
  }

  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .btn-group > .btn,
  .input-group-btn:first-child > .dropdown-toggle,
  .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    .border-right-radius(0);
  }

  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group > .btn,
  .input-group-btn:last-child > .dropdown-toggle,
  .input-group-btn:first-child > .btn:not(:first-child),
  .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    .border-left-radius(0);
  }
}
