//
// EDR Modifications to navbar.less
// --------------------------------------------------


// Alternate navbars
// --------------------------------------------------

// EDR navbar
.navbar, .navbar-default {
  background-color: @navbar-default-bg;
  border-color: @navbar-default-border;

  .navbar-brand {
    color: @navbar-default-brand-color;
    font-family: @headings-font-family;
    text-transform: uppercase;
    font-weight: normal;

    // add the edr logo to the brand text so that it is always there.
    background: transparent url("../images/EDR_blue70.png") no-repeat;
    background-size: 35px;
    background-position: 15px center;
    padding-left: 55px;

    &:hover,
    &:focus {
			&:before {
				content: "EDR ";
				font-weight: bold;
				color: #FFF;
			}

      color: @navbar-default-brand-hover-color;
      background-color: @navbar-default-brand-hover-bg;

      // hover edr logo is grey, same color as hover text
      background: transparent url("../images/EDR_white70.png") no-repeat;
      background-size: 35px;
      background-position: 15px center;
    }

    &:before {
      content: "EDR ";
      font-weight: bold;
      color: @brand-blue;
    }
  }


  // No hover version of .navbar-brand
  // ------------------------------------------------

  .navbar-brand-nohover {
    &:focus,
    &:hover {
      &:before {
        content: "EDR ";
        font-weight: bold;
        color: @brand-blue;
      }

      text-decoration: none;
      color: @navbar-default-brand-color;
      background-color: none;

      // add the edr logo to the brand text so that it is always there.
      background: transparent url("../images/EDR_blue70.png") no-repeat;
      background-size: 35px;
      background-position: 15px center;
    }
  }


  .navbar-text {
    color: @navbar-default-color;
  }

  .navbar-nav {
    > li > a {
      color: @navbar-default-link-color;
      font-size: @font-size-large;
      line-height: @line-height-computed;
      font-weight: bold;
      font-family: @headings-font-family;
      text-transform: uppercase;

      &:hover,
      &:focus {
        color: @navbar-default-link-hover-color;
        background-color: @navbar-default-link-hover-bg;
        //border-bottom: 4px solid @navbar-default-link-hover-color;
        //padding-bottom: 12px;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-active-color;
        background-color: @navbar-default-link-active-bg;
        //border-bottom: 4px solid @navbar-default-link-active-color;
        //padding-bottom: 12px;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
        background-color: @navbar-default-link-disabled-bg;
      }
    }
  }

  .navbar-toggle {
    border-color: @navbar-default-toggle-border-color;
    &:hover,
    &:focus {
      background-color: @navbar-default-toggle-hover-bg;
    }
    .icon-bar {
      background-color: @navbar-default-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: @navbar-default-border;
  }

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-default-link-hover-bg;
        color: @navbar-default-link-hover-color;

      }
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: @navbar-default-link-color;
          &:hover,
          &:focus {
            color: @navbar-default-link-hover-color;
            background-color: @navbar-default-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-hover-color;
            background-color: @navbar-default-link-hover-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-disabled-color;
            background-color: @navbar-default-link-disabled-bg;
          }
        }
      }
    }
  }

  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: @navbar-default-link-color;
    &:hover {
      color: @navbar-default-link-hover-color;
    }
  }

  .btn-link {
    color: @navbar-default-link-color;
    &:hover,
    &:focus {
      color: @navbar-default-link-hover-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
      }
    }
  }
}


// EDR navbar-inverse
.navbar-inverse {
  background-color: @navbar-inverse-bg;
  border-color: @navbar-inverse-border;

  .navbar-brand {
    color: @navbar-inverse-brand-color;
    font-family: @headings-font-family;
    text-transform: uppercase;
    font-weight: normal;

    // add the edr logo to the brand text so that it is always there.
    background: transparent url("../images/EDR_white70.png") no-repeat;
    background-size: 35px;
    background-position: 15px center;
    padding-left: 55px;

    &:hover,
    &:focus {
			&:before {
				color: @navbar-inverse-brand-hover-color;
				content: "EDR ";
				font-weight: bold;
			}
      color: @navbar-inverse-brand-hover-color;
      background-color: @navbar-inverse-brand-hover-bg;

      // hover edr logo is grey, same color as hover text
      background: transparent url("../images/EDR_white70.png") no-repeat;
      background-size: 35px;
      background-position: 15px center;
    }

    &:before {
      color: @navbar-inverse-brand-color;
      content: "EDR ";
      font-weight: bold;
    }
  }


  // No hover version of .navbar-brand
  // ------------------------------------------------

  .navbar-brand-nohover {
    &:focus,
    &:hover {
      &:before {
        content: "EDR ";
        font-weight: bold;
        color: @navbar-inverse-brand-color;
      }

      text-decoration: none;
      color: @navbar-inverse-brand-color;
      background-color: none;

      // add the edr logo to the brand text so that it is always there.
      background: transparent url("../images/EDR_white70.png") no-repeat;
      background-size: 35px;
      background-position: 15px center;
    }
  }


  .navbar-text {
    color: @navbar-inverse-color;
  }

  .navbar-nav {
		.dropdown-menu {
			border-top: 4px solid @navbar-inverse-link-hover-bg;
		}
    > li > a {
      color: @navbar-inverse-link-color;
      font-size: @font-size-large;
      line-height: @line-height-computed;
      font-weight: bold;
      font-family: @headings-font-family;
      text-transform: uppercase;

      &:hover,
      &:focus {
        color: @navbar-inverse-link-hover-color;
        background-color: @navbar-inverse-link-hover-bg;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-inverse-link-active-color;
        background-color: @navbar-inverse-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-inverse-link-disabled-color;
        background-color: @navbar-inverse-link-disabled-bg;
      }
    }
  }

  .navbar-toggle {
    border-color: @navbar-inverse-toggle-border-color;
    &:hover,
    &:focus {
      background-color: @navbar-inverse-toggle-hover-bg;
    }
    .icon-bar {
      background-color: @navbar-inverse-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: @navbar-inverse-border;
  }

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-inverse-link-hover-bg;
        color: @navbar-inverse-link-hover-color;
      }
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
				border-top: none;

				.dropdown-header {
  				color: #FFF;
				}
        > li > a {
          color: @navbar-inverse-link-color;
          &:hover,
          &:focus {
            color: @navbar-inverse-link-hover-color;
            background-color: @navbar-inverse-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-inverse-link-active-color;
            background-color: @navbar-inverse-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-inverse-link-disabled-color;
            background-color: @navbar-inverse-link-disabled-bg;
          }
        }
      }
    }
  }

  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: @navbar-inverse-link-color;
    &:hover {
      color: @navbar-inverse-link-hover-color;
    }
  }

  .btn-link {
    color: @navbar-inverse-link-color;
    &:hover,
    &:focus {
      color: @navbar-inverse-link-hover-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: @navbar-inverse-link-disabled-color;
      }
    }
  }
}


// Wrapper and base class
//
// Provide a larger static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar-lg {
  min-height: @navbar-height-large; // Ensure a navbar always shows (e.g., without a .navbar-brand in collapsed mode)

  // Brand/project name
  .navbar-brand {
    padding: @navbar-padding-vertical-large @navbar-padding-horizontal;
    height: @navbar-height-large;

    // edr logo
    background-size: 50px;
    padding-left: 70px;
    font-size: @font-size-xlarge;

    &:hover,
    &:focus {
      background-size: 50px;
      padding-left: 70px;
      font-size: @font-size-xlarge;
    }
  }

  // Navbar toggle
  .navbar-toggle {
    .navbar-vertical-align-lg(34px);
  }

  // Navbar nav large links
  .navbar-nav {
    //margin: (@navbar-padding-vertical-large / 2) -@navbar-padding-horizontal;

    > li > a {
      padding-top:    @navbar-padding-vertical-large;
      padding-bottom: @navbar-padding-vertical-large;
      font-size: @font-size-xlarge;
    }

    // Uncollapse the nav
    @media (min-width: @grid-float-breakpoint) {
      > li > a {
        padding-top:    @navbar-padding-vertical-large;
        padding-bottom: @navbar-padding-vertical-large;
      }
    }
  }

  // Navbar form large
  .navbar-form {
    // Vertically center in expanded, horizontal navbar
    .navbar-vertical-align-lg(@input-height-base);
  }
}
