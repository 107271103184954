//
// EDR Modifications to jumbotron.less
// --------------------------------------------------

// Inverted jumbotron
// --------------------------------------------------

.jumbotron-inverse {
  color: @jumbotron-inverse-color;
  background-color: @jumbotron-inverse-bg;

   h1,
  .h1 {
    color: @jumbotron-inverse-heading-color;
  }

  > hr {
    border-top-color: darken(@jumbotron-inverse-bg, 10%);
  }
}
