//
// EDR Modifications to Bootstrap labels.less
// --------------------------------------------------


//
// Labels
// --------------------------------------------------

.label {
  position: relative;
  font-size: 75%;
  vertical-align: middle;
  top: -4px;
}

h2 {
  > .label {
    top: -3px;
    padding: 0em .6em 0em;
  }
}

h3 {
  > .label {
    top: -2px;
  }
}

h4, h5, h6 {
  > .label {
    top: -1px;
  }
}
