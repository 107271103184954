//
// Dropdown menus
// --------------------------------------------------


// Dropdown section headers
.dropdown-header {
  color: @dropdown-header-color;
  font-size: ceil((@font-size-base * 0.78));
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 20px 3px 20px;
}

li.dropdown-header:first-child {
  padding-top: 3px;
}

// The dropdown menu (ul)
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: @zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: -4px 0 0 0; // override default ul
  list-style: none;
  font-size: @font-size-base;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  background-color: @dropdown-bg;
  border-color: @dropdown-border;
  border-style: solid;
  border-width: 4px 0 1px 0;
  border-radius: 0px;
  .border-bottom-radius(@border-radius-small);
  border-top: 4px solid @dropdown-border-top;
  .box-shadow(0px 1px 1px 0px rgba(0,0,0,0.2));
  background-clip: padding-box;

  // Aligns the dropdown menu to right
  //
  // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
  &.pull-right {
    right: 0;
    left: auto;
  }

  // Dividers (basically an hr) within the dropdown
  .divider {
    .nav-divider(@dropdown-divider-bg);
  }

  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 4px 20px;
    clear: both;
    font-weight: normal;
    line-height: @line-height-base;
    color: @dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
  }
}

// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    text-decoration: none;
    color: @dropdown-link-hover-color;
    background-color: @dropdown-link-hover-bg;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: @dropdown-link-active-color;
    text-decoration: none;
    outline: 0;
    background-color: @dropdown-link-active-bg;
    font-weight: 700;
  }
}


// Heavy bordered version of the dropdown
// to pair with .form-control-bordered.
.dropdown-menu-bordered {
  border: 2px solid @brand-primary;
  border-radius: (@border-radius-large * 2);
  border-top: 0px;
  .border-top-radius(0px);
}
