//
// EDR Modifications to create a tour panel.
// --------------------------------------------------

/*
 * THIS REQUIRES A MODAL WITH A CAROUSEL.
 *
 * SEE THE DOCS HERE: http://edrwebdevawvd1/uiux/bootstrap/edr/#tour-overlay
 */


.modal-tour {
  top: 100px;

  > .modal-dialog {

    > .modal-content {
      text-align: center;
      border-top: 1px solid @gray-lighter;
      border-bottom: none;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;

      > .modal-header {
        border-bottom: none;
        padding: 0;

        > h4 {
          text-align: center;
          color: @brand-primary;
          font-size: 36px;
        }
      }

      > .modal-body {
        text-align: center;

        > .carousel {
          > ol {
            &.carousel-indicators {
              bottom: 0;

              > li {
                border-color: @gray-lighter;
                background-color: @gray-lighter;
                padding: 5px;
                margin: 3px;

                &.active {
                  border-color: @brand-success;
                  background-color: @brand-success;
                }
              }
            }
          }

          > .carousel-inner {
            padding: 0 60px 40px 60px;

            > .item {

              > .fa {
                font-size: 144px;
                color: @brand-primary;
              }
            }
          }
        }
      }

      > .modal-footer {
        background-color: @brand-primary;
        border-top: none;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;

        > a {
          &.btn-link {
            color: @white;
          }
        }
      }
    }
  }
}
