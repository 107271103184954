//
// EDR Modifications to Bootstrap buttons.less
// --------------------------------------------------


// Additional button colors
// --------------------------------------------------
.btn-blue {
  .button-variant(@btn-blue-color; @btn-blue-bg; @btn-blue-border);
}

.btn-green {
  .button-variant(@btn-green-color; @btn-green-bg; @btn-green-border);
}

// Force standard button text to ALL CAPS
// --------------------------------------------------
.btn,
.btn-alt {
  text-transform: uppercase;
}


// Alternate button style
// For example, Lightbox 3 buttons
// --------------------------------------------------
.btn-alt {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-alt-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
  .user-select(none);
  .box-shadow(0px 1px 1px 0px rgba(0,0,0,0.2));
  text-shadow: 0 1px 0 rgba(0,0,0,.2);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      .tab-focus();
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: @btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    //.box-shadow(none);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: @cursor-disabled;
    .opacity(.65);
    .box-shadow(none);
  }

  a& {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }

  // Alternate Colors
  // ------------------------------------------------
  &.btn-default {
    .button-variant(@btn-default-color; @btn-default-bg; @btn-alt-default-border);

    text-shadow: none;

    // Override .button-variant border darkening
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle& {
      border-color: darken(@btn-alt-default-border, 5%);

      &:hover,
      &:focus,
      &.focus {
        background-color: darken(@btn-default-bg, 15%);
        border-color: darken(@btn-alt-default-border, 5%);
      }
    }

    & + ul.dropdown-menu {
      border-top: none;
      background-color: darken(@btn-default-bg, 15%);
      border-color: darken(@btn-alt-default-border, 5%);
      border-width: 1px;

      li.divider {
        background-color: darken(@btn-alt-default-border, 10%);
      }

      li > a {
        color: @btn-default-color;

        &:hover,
        &:focus {
          background-color: darken(@btn-alt-default-border, 5%);
          color: @btn-default-color;
        }
      }

      .active > a {
        color: @btn-default-color;

        &,
        &:hover,
        &:focus {
          background-color: darken(@btn-alt-default-border, 5%);
          color: @btn-default-color;
        }
      }

      .disabled > a {
        &,
        &:hover,
        &:focus {
          color: darken(@btn-alt-default-border, 20%);
        }

        // Nuke hover/focus effects
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: transparent;
          background-image: none; // Remove CSS gradient
          .reset-filter();
          cursor: @cursor-disabled;
        }
      }
    }
  }

  &.btn-primary {
    .button-variant(@btn-primary-color; @btn-primary-bg; @btn-alt-primary-border);

    // Override .button-variant border darkening
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle& {
      border-color: darken(@btn-alt-primary-border, 5%);

      &:hover,
      &:focus,
      &.focus {
        background-color: darken(@btn-primary-bg, 15%);
        border-color: darken(@btn-alt-primary-border, 5%);
      }
    }

    & + ul.dropdown-menu {
      border-top: none;
      background-color: darken(@btn-primary-bg, 15%);
      border-color: darken(@btn-alt-primary-border, 5%);
      border-width: 1px;

      li.divider {
        background-color: darken(@btn-alt-primary-border, 10%);
      }

      li > a {
        color: @btn-primary-color;

        &:hover,
        &:focus {
          background-color: darken(@btn-alt-primary-border, 5%);
          color: @btn-primary-color;
        }
      }

      .active > a {
        color: @btn-primary-color;

        &,
        &:hover,
        &:focus {
          background-color: darken(@btn-alt-primary-border, 5%);
          color: @btn-primary-color;
        }
      }

      .disabled > a {
        &,
        &:hover,
        &:focus {
          color: darken(@btn-alt-primary-border, 25%);
        }

        // Nuke hover/focus effects
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: transparent;
          background-image: none; // Remove CSS gradient
          .reset-filter();
          cursor: @cursor-disabled;
        }
      }
    }
  }

  // Success appears as green
  &.btn-success {
    .button-variant(@btn-alt-success-color; @btn-success-bg; @btn-alt-success-border);

    // Override .button-variant border darkening
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle& {
      border-color: darken(@btn-alt-success-border, 5%);

      &:hover,
      &:focus,
      &.focus {
        background-color: darken(@btn-success-bg, 15%);
        border-color: darken(@btn-alt-success-border, 5%);
      }
    }

    & + ul.dropdown-menu {
      border-top: none;
      background-color: darken(@btn-success-bg, 15%);
      border-color: darken(@btn-alt-success-border, 5%);
      border-width: 1px;

      li.divider {
        background-color: darken(@btn-alt-success-border, 10%);
      }

      li > a {
        color: @btn-alt-success-color;

        &:hover,
        &:focus {
          background-color: darken(@btn-alt-success-border, 5%);
          color: @btn-alt-success-color;
        }
      }

      .active > a {
        color: @btn-alt-success-color;

        &,
        &:hover,
        &:focus {
          background-color: darken(@btn-alt-success-border, 5%);
          color: @btn-alt-success-color;
        }
      }

      .disabled > a {
        &,
        &:hover,
        &:focus {
          color: darken(@btn-alt-success-border, 20%);
        }

        // Nuke hover/focus effects
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: transparent;
          background-image: none; // Remove CSS gradient
          .reset-filter();
          cursor: @cursor-disabled;
        }
      }
    }
  }

  // Info appears as blue-green
  &.btn-info {
    .button-variant(@btn-info-color; @btn-info-bg; @btn-alt-info-border);

    // Override .button-variant border darkening
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle& {
      border-color: darken(@btn-alt-info-border, 5%);

      &:hover,
      &:focus,
      &.focus {
        background-color: darken(@btn-info-bg, 15%);
        border-color: darken(@btn-alt-info-border, 5%);
      }
    }

    & + ul.dropdown-menu {
      border-top: none;
      background-color: darken(@btn-info-bg, 15%);
      border-color: darken(@btn-alt-info-border, 5%);
      border-width: 1px;

      li.divider {
        background-color: darken(@btn-alt-info-border, 10%);
      }

      li > a {
        color: @btn-info-color;

        &:hover,
        &:focus {
          background-color: darken(@btn-alt-info-border, 5%);
          color: @btn-info-color;
        }
      }

      .active > a {
        color: @btn-info-color;

        &,
        &:hover,
        &:focus {
          background-color: darken(@btn-alt-info-border, 5%);
          color: @btn-info-color;
        }
      }

      .disabled > a {
        &,
        &:hover,
        &:focus {
          color: darken(@btn-alt-info-border, 20%);
        }

        // Nuke hover/focus effects
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: transparent;
          background-image: none; // Remove CSS gradient
          .reset-filter();
          cursor: @cursor-disabled;
        }
      }
    }
  }

  // Warning appears as orange
  &.btn-warning {
    .button-variant(@btn-warning-color; @btn-warning-bg; @btn-alt-warning-border);

    // Override .button-variant border darkening
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle& {
      border-color: darken(@btn-alt-warning-border, 5%);

      &:hover,
      &:focus,
      &.focus {
        background-color: darken(@btn-warning-bg, 15%);
        border-color: darken(@btn-alt-warning-border, 5%);
      }
    }

    & + ul.dropdown-menu {
      border-top: none;
      background-color: darken(@btn-warning-bg, 15%);
      border-color: darken(@btn-alt-warning-border, 5%);
      border-width: 1px;

      li.divider {
        background-color: darken(@btn-alt-warning-border, 10%);
      }

      li > a {
        color: @btn-warning-color;

        &:hover,
        &:focus {
          background-color: darken(@btn-alt-warning-border, 5%);
          color: @btn-warning-color;
        }
      }

      .active > a {
        color: @btn-warning-color;

        &,
        &:hover,
        &:focus {
          background-color: darken(@btn-alt-warning-border, 5%);
          color: @btn-warning-color;
        }
      }

      .disabled > a {
        &,
        &:hover,
        &:focus {
          color: darken(@btn-alt-warning-border, 20%);
        }

        // Nuke hover/focus effects
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: transparent;
          background-image: none; // Remove CSS gradient
          .reset-filter();
          cursor: @cursor-disabled;
        }
      }
    }
  }

  // Danger and error appear as red
  &.btn-danger {
    .button-variant(@btn-danger-color; @btn-danger-bg; @btn-alt-danger-border);

    // Override .button-variant border darkening
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle& {
      border-color: darken(@btn-alt-danger-border, 5%);

      &:hover,
      &:focus,
      &.focus {
        background-color: darken(@btn-danger-bg, 15%);
        border-color: darken(@btn-alt-danger-border, 5%);
      }
    }

    & + ul.dropdown-menu {
      border-top: none;
      background-color: darken(@btn-danger-bg, 15%);
      border-color: darken(@btn-alt-danger-border, 5%);
      border-width: 1px;

      li.divider {
        background-color: darken(@btn-alt-danger-border, 10%);
      }

      li > a {
        color: @btn-danger-color;

        &:hover,
        &:focus {
          background-color: darken(@btn-alt-danger-border, 5%);
          color: @btn-danger-color;
        }
      }

      .active > a {
        color: @btn-danger-color;

        &,
        &:hover,
        &:focus {
          background-color: darken(@btn-alt-danger-border, 5%);
          color: @btn-danger-color;
        }
      }

      .disabled > a {
        &,
        &:hover,
        &:focus {
          color: darken(@btn-alt-danger-border, 20%);
        }

        // Nuke hover/focus effects
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: transparent;
          background-image: none; // Remove CSS gradient
          .reset-filter();
          cursor: @cursor-disabled;
        }
      }
    }
  }

  // Info appears as blue-green
  &.btn-infoactive {
    .button-variant(@btn-info-color; @btn-info-bg; @btn-alt-info-border);

    // Override .button-variant border darkening
    &:focus,
    &.focus {
      color: @btn-info-color;
      background-color: darken(@btn-info-bg, 15%);
      border-color: darken(@btn-alt-info-border, 5%);
    }

    //&:active,
    &.active {
      color: @btn-alt-success-color;
      background-color: @btn-success-bg;
      border-color: @btn-alt-success-border;

      &:hover,
      &:focus,
      &.focus {
        color: @btn-alt-success-color;
        background-color: darken(@btn-success-bg, 15%);
        border-color: darken(@btn-alt-success-border, 5%);
      }
    }

    .open > .dropdown-toggle& {
      color: @btn-alt-success-color;
      background-color: darken(@btn-success-bg, 15%);
      border-color: darken(@btn-alt-success-border, 5%);

      &:hover,
      &:focus,
      &.focus {
        color: @btn-info-color;
        background-color: darken(@btn-info-bg, 15%);
        border-color: darken(@btn-alt-info-border, 5%);
      }
    }

    .open > .dropdown-toggle.active& {
      color: @btn-alt-success-color;
      background-color: darken(@btn-success-bg, 15%);
      border-color: darken(@btn-alt-success-border, 5%);
    }

    & + ul.dropdown-menu {
      border-top: none;
      background-color: darken(@btn-info-bg, 15%);
      border-color: darken(@btn-alt-info-border, 5%);
      border-width: 1px;

      li.divider {
        background-color: darken(@btn-alt-info-border, 10%);
      }

      li > a {
        color: @btn-info-color;

        &:hover,
        &:focus {
          background-color: darken(@btn-alt-info-border, 5%);
          color: @btn-info-color;
        }
      }

      .active > a {
        color: @btn-info-color;

        &,
        &:hover,
        &:focus {
          background-color: darken(@btn-alt-info-border, 5%);
          color: @btn-info-color;
        }
      }

      .disabled > a {
        &,
        &:hover,
        &:focus {
          color: darken(@btn-alt-info-border, 20%);
        }

        // Nuke hover/focus effects
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: transparent;
          background-image: none; // Remove CSS gradient
          .reset-filter();
          cursor: @cursor-disabled;
        }
      }
    }
  }

  // Remove shadow from btn-link
  &.btn-link {
    .box-shadow(none);
    text-shadow: none;

    &:hover,
    &:focus,
    &.focus {
      text-decoration: underline;
    }
  }

  &.btn-success,
  &.btn-infoactive {
    text-shadow: none;
  }

  // Alternate Sizes
  // ------------------------------------------------

  &.btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
  }
  &.btn-sm {
    // line-height: ensure proper height of button next to small input
    .button-size(4px; 8px; @font-size-xs; @line-height-xs; @btn-border-radius-xs);
  }
  &.btn-xs {
    .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-xs; @line-height-xs; @btn-border-radius-xs);
  }
}



// Fix for blue outline on buttons
// ------------------------------------------------

button {
  &:active,
  &:focus,
  &:hover {
    outline: none !important;
  }
}

.btn {
  &:active,
  &:focus,
  &:hover {
    outline: none !important;
  }
}
