//
// EDR Modifications to Bootstrap forms.less
// --------------------------------------------------

select {
  option {
    &:disabled {
      color: #CCC;
    }
  }
}

.form-control-bordered {
  border: 2px solid @brand-primary;
  border-radius: (@border-radius-large * 2);
}

// Form control sizing
//
// Adding an .input-xs class
// --------------------------------------------------


// Static form control text
//
// Apply class to a `p` element to make any string of text align with labels in
// a horizontal form layout.

.form-control-static {
  &.input-xs {
    padding-left: 0;
    padding-right: 0;
  }
}

// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// The `.form-group-* form-control` variations are sadly duplicated to avoid the
// issue documented in https://github.com/twbs/bootstrap/issues/15074.

.input-xs {
  .input-size(@input-height-xs; @padding-xs-vertical; @padding-xs-horizontal; @font-size-xs; @line-height-xs; @input-border-radius-xs);
}

.form-group-xs {
  .form-control {
    height: @input-height-xs;
    padding: @padding-xs-vertical @padding-xs-horizontal;
    font-size: @font-size-xs;
    line-height: @line-height-xs;
    border-radius: @input-border-radius-xs;
  }
  select.form-control {
    height: @input-height-xs;
    line-height: @input-height-xs;
  }
  textarea.form-control,
  select[multiple].form-control {
    height: auto;
  }
  .form-control-static {
    height: @input-height-xs;
    min-height: (@line-height-computed + @font-size-xs);
    padding: (@padding-xs-vertical + 1) @padding-xs-horizontal;
    font-size: @font-size-xs;
    line-height: @line-height-xs;
  }
}

.input-xs + .form-control-feedback,
.input-group-xs + .form-control-feedback,
.form-group-xs .form-control + .form-control-feedback {
  width: @input-height-xs;
  height: @input-height-xs;
  line-height: @input-height-xs;
}

.form-horizontal {
  .form-group-xs {
    @media (min-width: @screen-sm-min) {
      .control-label {
        padding-top: (@padding-xs-vertical + 1);
        font-size: @font-size-xs;
      }
    }
  }
}
