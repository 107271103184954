//
// EDR Modifications to Bootstrap utilities.less
// --------------------------------------------------

// Background Cover
// Make an image fill the full browser viewport
//
// Example:
//
//  <body>
//    <div id="background-cover">
//      <img src="image.jpg" />
//    </div>
//    ...
//  </body>
//
// -------------------------

#background-cover {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

#background-cover img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
}
