//
// EDR Modifications to Bootstrap modals.less
// --------------------------------------------------


//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Actual modal
.modal-content {
  position: relative;
  background-color: @modal-content-bg;
  border-color: @modal-content-border-color;
  border-style: solid;
  border-width: 4px 0 1px 0;
  border-top: 4px solid @modal-border-top;
  border-radius: 0px;
  .border-bottom-radius(@border-radius-small);
  .box-shadow(0px 1px 1px 0px rgba(0,0,0,0.2));
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: 0;
}
