//
// EDR Modifications to create priority alerts.
// --------------------------------------------------

/*
 * THIS REQUIRES A MODAL.
 *
 * SEE THE DOCS HERE: http://edrwebdevawvd1/uiux/bootstrap/edr/#edr-priority
 */


.modal-priority-alert {
  top: 100px;

  > .modal-dialog {

    > .modal-content {
      text-align: center;
      border-top: 1px solid @gray-lighter;
      border-bottom: none;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
      padding-top: 10px;

      > .modal-header {
        border-bottom: none;
        padding: 0;

        > h4 {
          text-align: center;
          color: @brand-danger;
          font-size: 36px;
        }

        > .icon {
          position: relative;

          > .fa {
            position: absolute;
            font-size: 300px;
            color: @brand-danger;
            top: 0;
            right: 0;
            overflow: hidden;
            .opacity(.10);
            width: 240px;
            padding-top: 10px;
          }
        }
      }

      > .modal-body {
        text-align: center;
        min-height: 270px;

        > .content {
          padding: 0 50px;
          margin: 0 auto;
          z-index: 2;

          @media (max-width: @grid-float-breakpoint-max) {
            padding: 0;
          }
        }
      }

      > .modal-footer {
        background-color: @brand-danger;
        border-top: none;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;

        > a {
          &.btn-link {
            color: @white;
          }
        }
      }
    }
  }
}

.modal-priority-warning {
  top: 100px;

  > .modal-dialog {

    > .modal-content {
      text-align: center;
      border-top: 1px solid @gray-lighter;
      border-bottom: none;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
      padding-top: 10px;

      > .modal-header {
        border-bottom: none;
        padding: 0;

        > h4 {
          text-align: center;
          color: @brand-warning;
          font-size: 36px;
        }

        > .icon {
          position: relative;

          > .fa {
            position: absolute;
            font-size: 300px;
            color: @brand-warning;
            top: 0;
            right: 0;
            overflow: hidden;
            .opacity(.10);
            width: 240px;
            padding-top: 10px;
          }
        }
      }

      > .modal-body {
        text-align: center;
        min-height: 270px;

        > .content {
          padding: 0 50px;
          margin: 0 auto;
          z-index: 2;

          @media (max-width: @grid-float-breakpoint-max) {
            padding: 0;
          }
        }
      }

      > .modal-footer {
        background-color: @brand-warning;
        border-top: none;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;

        > a {
          &.btn-link {
            color: @white;
          }
        }
      }
    }
  }
}
