//
// EDR Modifications to Bootstrap breadcrumbs.less
// --------------------------------------------------


.breadcrumb {
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: @line-height-computed;
  list-style: none;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;
  text-transform: uppercase;

  > li {
    display: inline-block;

    + li:before {
      content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @breadcrumb-color;
    }
  }

  > .active {
    color: @breadcrumb-active-color;
  }
}

.breadcrumb-inverse {
  background-color: @breadcrumb-inverse-bg;

  > li {

    > a {
      color: @breadcrumb-inverse-color;
      &:hover,
      &:focus {
        color: @breadcrumb-inverse-color;
      }
    }

    +li:before {
      color: @breadcrumb-inverse-color;
    }
  }

  > .active {
    color: @breadcrumb-inverse-active-color;
  }
}
